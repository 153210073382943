import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { T } from "../traks";
import FixedContactButton from "c/FixedContactButton";
import { TeamList } from "c/Cards";

const AboutUsInner = () => {

	const heroImg = "https://static.optimeet.dk/imgs/aboutus-1920x890px.webp";

	const employees = [
		{
			"id": "1",
			"name": "Ole Sorang",
			"jobTitle": "Adm. Direktør",
			"phone": "29 28 79 55",
			"email": "olso@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/ole-sorang-a326824/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_ole_sorang_300x420-0.webp"
		},
		{
			"id": "2",
			"name": "Jacob Nedergaard",
			"jobTitle": "COO Exhibitions & Events ",
			"phone": "51 50 13 32",
			"email": "jne@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/jacob-nedergaard-40559a1/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_jacob_nedergaard_300x420-1.webp"
		},
		{
			"id": "3",
			"name": "Charlotte Jørgensen",
			"jobTitle": "Key Account Manager",
			"phone": "38 14 60 45",
			"email": "cj@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/charlotte-j%C3%B8rgensen-bb54bb32/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_charlotte_joergensen_300x420-1.webp"
		},
		{
			"id": "4",
			"name": "Kathrine Henriksen",
			"jobTitle": "Konference & Booking Manager",
			"phone": "88 77 97 89",
			"email": "kahe@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/kathrinesverdruphenriksen/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_kathrine_henriksen_300x420-1.webp"
		},
		{
			"id": "5",
			"name": "Sandra Bergstrøm",
			"jobTitle": "Head of Relations & Events",
			"phone": "38 14 60 41",
			"email": "sb@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/sandrabergstroem/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_sandra_bergstroem_300x420-1.webp"
		},
		{
			"id": "6",
			"name": "Pia Dewachter",
			"jobTitle": "Coordinator, Relations & Events",
			"phone": "38 14 60 40",
			"email": "pd@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/pia-dewachter-b5b6abb/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_pia_dewachter_300x420-1.webp"
		},
		{
			"id": "7",
			"name": "Alexander Holde",
			"jobTitle": "Marketing Manager",
			"phone": "60 13 76 72 ",
			"email": "alho@opusgroup.dk",
			"linkedIn": "https://www.linkedin.com/in/alexander-holde-larsen-520aa9203/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_alexander_300x420px-2.webp"
		},
		{
			"id": "8",
			"name": "Jan Aagaard",
			"jobTitle": "Chefredaktør",
			"phone": "60 15 58 06",
			"email": "ja@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/janaagaarddk/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_jan_aagaard_300x420-0.jpg"
		},
		{
			"id": "9",
			"name": "Isabell Strouhal",
			"jobTitle": "Studerende",
			"phone": "38 14 60 47",
			"email": "isst@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/isabell-strouhal-bba502204/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_isabell_300x420px-2.webp"
		},
	];

	return (
		<Container>
			<Row style={{paddingBottom: "100px"}}>
				<Col className="pt-5">
					<img alt="hero" className="mb-5" src={heroImg} style={{width: "100%", borderRadius: 10}}/>
					<div className="whitebox fade-up">
						<h1><T>Om os</T></h1>
						<T>
							<p>
								OPTIMEET er Skandinaviens største internationale markedsplads indenfor møde- og eventbranchen.
							</p>
							<p>
								Vi skaber viden, værdi og relationer og forbinder møde- og eventindkøbere i
								virksomheder og organisation med hoteller, venues og andre leverandører i
								møde- og eventbranchen. Vi driver en bred vifte af fysiske og online
								aktiviteter -- fra messer, inspirationsdage og netværk til bookingplatforme,
								medier og analyser.
							</p>
							<p>
								OPTIMEET er en del af Opus Group-koncernen, der årligt formidler og gennemfører tusindvis af møder,
								konferencer, events, foredrag og oplevelser på tværs af lande og brands.
							</p>
						</T>
						<h4 className="mt-5 text-bold">Teamet bag Optimeet</h4>
						<div className="mt-5">
							<TeamList col={2} data={employees} />
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

const AboutUs = () => {
	return (
		<div>
			<Header />
			<AboutUsInner />
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default AboutUs;
