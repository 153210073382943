import React from "react";
/* eslint-disable import/no-anonymous-default-export */
const O = React.Fragment;
export default {
	"346b337db6d6": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Månadens hotel</O>,
		"da": () => <O>Månadens hotel</O>,
	},

	"20d2c10cb448": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Populært</O>,
		"da": () => <O>Populært</O>,
	},

	"b9cef11d7c4f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>PRISER FRA</O>,
		"da": () => <O>PRISER FRA</O>,
	},

	"f064e1820e86": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>DKK</O>,
		"da": () => <O>DKK</O>,
	},

	"b699cec85920": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>ANMELDELSER</O>,
		"da": () => <O>ANMELDELSER</O>,
	},

	"089b374533e8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Siden kan ikke findes</O>,
		"da": () => <O>Siden kan ikke findes</O>,
	},

	"25394fb79279": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>konferencesteder</O>,
		"da": () => <O>konferencesteder</O>,
	},

	"b5dd790b2a3f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>eller søg</O>,
		"da": () => <O>eller søg</O>,
	},

	"3eb7e4e032d4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>hjemmesiden</O>,
		"da": () => <O>hjemmesiden</O>,
	},

	"ab280369ca48": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gå tilbage til</O>,
		"da": () => <O>Gå tilbage til</O>,
	},

	"139a99445c69": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Månedens hotel</O>,
		"da": () => <O>Månedens hotel</O>,
	},

	"682969f2f7ab": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Søg på venues</O>,
		"da": () => <O>Søg på venues</O>,
	},

	"af554d1c95c3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vælg venues</O>,
		"da": () => <O>Vælg venues</O>,
	},

	"d852fe0258e2": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Populære konferencested</O>,
		"da": () => <O>Populære konferencested</O>,
	},

	"f1607119922c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Tilmeld</O>,
		"da": () => <O>Tilmeld</O>,
	},

	"90d44c7db5b5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>name</O>,
		"da": () => <O>navn</O>,
	},

	"528b51303a08": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>titel</O>,
		"da": () => <O>titel</O>,
	},

	"8df05c5cdfd4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>e-mail</O>,
		"da": () => <O>e-mail</O>,
	},

	"8bd3eb51db7c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>firma</O>,
		"da": () => <O>firma</O>,
	},

	"0abcf471d8fb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>telefon nr</O>,
		"da": () => <O>telefon nr</O>,
	},

	"70665ad5b6f7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>land</O>,
		"da": () => <O>land</O>,
	},

	"423a33b75bf4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>postnummer</O>,
		"da": () => <O>postnummer</O>,
	},

	"e883502439e6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilmeld nyhedsbrev</O>,
		"da": () => <O>Tilmeld nyhedsbrev</O>,
	},

	"a005a7d6543a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilmeld</O>,
		"da": () => <O>Tilmeld</O>,
	},

	"41c9c8c61c16": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hold dig opdateret med nyt fra optimeet.dk</O>,
		"da": () => <O>Hold dig opdateret med nyt fra optimeet.dk</O>,
	},

	"4e432aec487c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Tilmeld nyhedsbrev</O>,
		"da": () => <O>Tilmeld nyhedsbrev</O>,
	},

	"51caff6fb365": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilbage til forsiden</O>,
		"da": () => <O>Tilbage til forsiden</O>,
	},

	"91a65522eb5b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Leverandør</O>,
		"da": () => <O>Leverandør</O>,
	},

	"ac9abf8f6f67": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilmelding</O>,
		"da": () => <O>Tilmelding</O>,
	},

	"b593281bac16": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilmeld dig Optimeet News og modtag:</O>,
		"da": () => <O>Tilmeld dig Optimeet News og modtag:</O>,
	},

	"3521130359eb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ugentlig nyhedsmail med sidste nyt fra møde- og eventbranchen samt faglig inspiration til dine møder og konferencer</O>,
		"da": () => <O>Ugentlig nyhedsmail med sidste nyt fra møde- og eventbranchen samt faglig inspiration til dine møder og konferencer</O>,
	},

	"d44cdfceaed9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Invitationer til gratis inspirationsarrangementer, workshops og messer</O>,
		"da": () => <O>Invitationer til gratis inspirationsarrangementer, workshops og messer</O>,
	},

	"cc9c45b5974a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Særtilbud og nyheder fra leverandører i møde- og eventbranchen</O>,
		"da": () => <O>Særtilbud og nyheder fra leverandører i møde- og eventbranchen</O>,
	},

	"d91aaca84297": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>din data</O>,
		"da": () => <O>din data</O>,
	},

	"b5dcfd4fef6b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Planlægger</O>,
		"da": () => <O>Planlægger</O>,
	},

	"9cd46b16afdf": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilmeldingsformular</O>,
		"da": () => <O>Tilmeldingsformular</O>,
	},

	"9db9d59268be": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Sådan håndterer Optimeet</O>,
		"da": () => <O>Sådan håndterer Optimeet</O>,
	},

	"690806e5fb4e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Login</O>,
		"da": () => <O>Login</O>,
	},

	"c6cdf4751db3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No landing category</O>,
		"da": () => <O>No landing category</O>,
	},

	"ea0eefc5fe0c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No supplier found</O>,
		"da": () => <O>No supplier found</O>,
	},

	"cee4e7a4a617": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Persondatapolitik</O>,
		"da": () => <O>Persondatapolitik</O>,
	},

	"349a0149b61b": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Optimeet insights</O>,
		"da": () => <O>Optimeet insights</O>,
	},

	"9ee49c054f6f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>insights</O>,
		"da": () => <O>insights</O>,
	},

	"9b370be07f18": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Your user is not premium yet</O>,
		"da": () => <O>Your user is not premium yet</O>,
	},

	"cce278c210ca": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Become premium and get access to Optimeet insights in order to inspect the number of visitors</O>,
		"da": () => <O>Become premium and get access to Optimeet insights in order to inspect the number of visitors</O>,
	},

	"322895b2cf4b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>and their behavior</O>,
		"da": () => <O>and their behavior</O>,
	},

	"8e8377327da4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Get premium</O>,
		"da": () => <O>Get premium</O>,
	},

	"270f291b591b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Optimeet Premium</O>,
		"da": () => <O>Optimeet Premium</O>,
	},

	"085a942cff7f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => {
			return (
				<O>
				    Get more advantages with Optimeet Premium like statistics, other stuff and this great stuff. Submit to learn more.
				</O>
			);
		},
		"da": () => {
			return (
				<O>
				    Get more advantages with Optimeet Premium like statistics, other stuff and this great stuff. Submit to learn more.
				</O>
			);
		},
	},

	"ac4f3065757f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>PHONE NO</O>,
		"da": () => <O>PHONE NO</O>,
	},

	"a9921f5d4a57": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Yes please, call me</O>,
		"da": () => <O>Yes please, call me</O>,
	},

	"b85f8718a7e3": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Become premium and get access to Optimeet insights in order to inspect the number of visitors and their behavior</O>,
		"da": () => <O>Become premium and get access to Optimeet insights in order to inspect the number of visitors and their behavior</O>,
	},

	"eb2478da8b69": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>latest 30 days</O>,
		"da": () => <O>latest 30 days</O>,
	},

	"39a4ea967e28": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Unique Visitors</O>,
		"da": () => <O>Unique Visitors</O>,
	},

	"fb9a3b69a6ef": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Total pageviews</O>,
		"da": () => <O>Total pageviews</O>,
	},

	"05e6f71edaa3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>visits staying</O>,
		"da": () => <O>visits staying</O>,
	},

	"89c35a89c07a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Average seconds on page (sec)</O>,
		"da": () => <O>Average seconds on page (sec)</O>,
	},

	"30e10e142b0c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Average seconds on page</O>,
		"da": () => <O>Average seconds on page</O>,
	},

	"7af6c465b93f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger banners</O>,
		"da": () => <O>Rediger banners</O>,
	},

	"cc8068e559a7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Optimeet News</O>,
		"da": () => <O>Optimeet News</O>,
	},

	"f14b509798dd": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Reset password</O>,
		"da": () => <O>Reset password</O>,
	},

	"957b60af3f36": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No news found</O>,
		"da": () => <O>No news found</O>,
	},

	"eed0cec5ecee": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Optimeet News</O>,
		"da": () => <O>Optimeet News</O>,
	},

	"84eeb6802a0b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Optimeet Magasinet</O>,
		"da": () => <O>Optimeet Magasinet</O>,
	},

	"8312e7a52c7a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ønsker du at modtage vores gratis magasin 4 gange om året, kan du bestille det her:</O>,
		"da": () => <O>Ønsker du at modtage vores gratis magasin 4 gange om året, kan du bestille det her:</O>,
	},

	"8a72ccfdc438": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bestil magasinet her</O>,
		"da": () => <O>Bestil magasinet her</O>,
	},

	"18450d2a6269": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger region</O>,
		"da": () => <O>Rediger region</O>,
	},

	"878b35989ce6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partner login</O>,
		"da": () => <O>Partner login</O>,
	},

	"e1eb91616a5b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Email</O>,
		"da": () => <O>Email</O>,
	},

	"4a82081bb9b1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indsæt venligst gyldig e-mail</O>,
		"da": () => <O>Indsæt venligst gyldig e-mail</O>,
	},

	"a9163e37bca4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Website URL</O>,
		"da": () => <O>Website URL</O>,
	},

	"c3a4b571b976": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dollhouse 3D URL</O>,
		"da": () => <O>Dollhouse 3D URL</O>,
	},

	"a864dbffc25e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Phone</O>,
		"da": () => <O>Phone</O>,
	},

	"fd93ef812ef7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Admin</O>,
		"da": () => <O>Admin</O>,
	},

	"4c60481eb6bf": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>FAQ</O>,
		"da": () => <O>FAQ</O>,
	},

	"57a3b30aa27b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gem</O>,
		"da": () => <O>Gem</O>,
	},

	"713ff2944be5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Add minimum 3 images, description, category, longitude and latitude before supplier can be made visible</O>,
		"da": () => <O>Add minimum 3 images, description, category, longitude and latitude before supplier can be made visible</O>,
	},

	"2129a22a836d": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Add minimum 3 images, description, longitude and latitude before supplier can be made visible</O>,
		"da": () => <O>Add minimum 3 images, description, longitude and latitude before supplier can be made visible</O>,
	},

	"7ffb16b7a4eb": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Add minimum 3 images, description, capacities, longitude and latitude before venue can be made visible</O>,
		"da": () => <O>Add minimum 3 images, description, capacities, longitude and latitude before venue can be made visible</O>,
	},

	"669983322960": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Add minimum 3 images, description, longitude and latitude before venue can be made visible</O>,
		"da": () => <O>Add minimum 3 images, description, longitude and latitude before venue can be made visible</O>,
	},

	"ab53ded94c2b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete banner</O>,
		"da": () => <O>Delete banner</O>,
	},

	"d7b3a36e4e3b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete banner' to delete the item</O>,
		"da": () => <O>Type 'delete banner' to delete the item</O>,
	},

	"ff805c0b6287": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>This section is intended for advanced users and SEO optimization. Edit at own risk.</O>,
		"da": () => <O>This section is intended for advanced users and SEO optimization. Edit at own risk.</O>,
	},

	"fbfb5abbc43c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Premium</O>,
		"da": () => <O>Premium</O>,
	},

	"3c94c60db464": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Promote this supplier</O>,
		"da": () => <O>Promote this supplier</O>,
	},

	"5e23678824c1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>When set above 1 the supplier will be listed at the top</O>,
		"da": () => <O>When set above 1 the supplier will be listed at the top</O>,
	},

	"5b77fe5b7f5e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Promote this venue</O>,
		"da": () => <O>Promote this venue</O>,
	},

	"637ef1c3f332": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>When set above 1 the venue will be listed at the top</O>,
		"da": () => <O>When set above 1 the venue will be listed at the top</O>,
	},

	"21776494fc5f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Skyscraper banner skal minimum være 160px i bredden og 600px i højde</O>,
		"da": () => <O>Skyscraper banner skal minimum være 160px i bredden og 600px i højde</O>,
	},

	"d6862e043a9a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Banner synligt på hjemmesiden</O>,
		"da": () => <O>Banner synligt på hjemmesiden</O>,
	},

	"01834d0032c7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Klik på Gem for at gøre ændringer synlige</O>,
		"da": () => <O>Klik på Gem for at gøre ændringer synlige</O>,
	},

	"6b7cbcc36e35": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Billboard banner skal minimum være 970px i bredden og 250px i højde</O>,
		"da": () => <O>Billboard banner skal minimum være 970px i bredden og 250px i højde</O>,
	},

	"dd6a34e185fb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger Banner</O>,
		"da": () => <O>Rediger Banner</O>,
	},

	"59968a2c4c93": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>When set above 1 the venue will be listed at the top, whenever a region or category is filtered</O>,
		"da": () => <O>When set above 1 the venue will be listed at the top, whenever a region or category is filtered</O>,
	},

	"991682f0fa69": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>When set 4 or 5 the venue will be listed at the top, whenever a region or category is filtered</O>,
		"da": () => <O>When set 4 or 5 the venue will be listed at the top, whenever a region or category is filtered</O>,
	},

	"ff4e6a656f79": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>When set as “Yes” the venue will be listed at the top, whenever a region or category is filtered</O>,
		"da": () => <O>When set as “Yes” the venue will be listed at the top, whenever a region or category is filtered</O>,
	},

	"7bbe7c20ae6c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Meta Title</O>,
		"da": () => <O>Meta Title</O>,
	},

	"9bda17d868af": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Meta Description</O>,
		"da": () => <O>Meta Description</O>,
	},

	"5b299b5a44db": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete item</O>,
		"da": () => <O>Delete item</O>,
	},

	"7b3189906430": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete item' to delete the item</O>,
		"da": () => <O>Type 'delete item' to delete the item</O>,
	},

	"c35e1905b94d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete user</O>,
		"da": () => <O>Delete user</O>,
	},

	"d129ab82b466": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete user' to delete the user</O>,
		"da": () => <O>Type 'delete user' to delete the user</O>,
	},

	"34d13acd1ac4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Change status</O>,
		"da": () => <O>Change status</O>,
	},

	"99e675f6f662": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Status</O>,
		"da": () => <O>Status</O>,
	},

	"7f0991f7a4cd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Inactive</O>,
		"da": () => <O>Inactive</O>,
	},

	"c9ff15cc8bcd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Active</O>,
		"da": () => <O>Active</O>,
	},

	"9f8ec136bbf3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No venues assigned to you</O>,
		"da": () => <O>No venues assigned to you</O>,
	},

	"3fcec2d26fa9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No suppliers assigned to you</O>,
		"da": () => <O>No suppliers assigned to you</O>,
	},

	"474a0177df6a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Landing Kategorier</O>,
		"da": () => <O>Landing Kategorier</O>,
	},

	"e22e7f6f1134": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Templates til kategorier</O>,
		"da": () => <O>Templates til kategorier</O>,
	},

	"7b697ec18edd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Templates til regioner</O>,
		"da": () => <O>Templates til regioner</O>,
	},

	"a4fb2ca51422": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Search</O>,
		"da": () => <O>Search</O>,
	},

	"00b5a1382ecc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Landing regioner</O>,
		"da": () => <O>Landing regioner</O>,
	},

	"2d841e4d95dd": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Landing kategorier</O>,
		"da": () => <O>Landing kategorier</O>,
	},

	"b4c568fcadf3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Supplier</O>,
		"da": () => <O>Supplier</O>,
	},

	"4b643b03360f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mine suppliers</O>,
		"da": () => <O>Mine suppliers</O>,
	},

	"341d0b1ac336": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Aktive suppliers</O>,
		"da": () => <O>Aktive suppliers</O>,
	},

	"840459202711": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Users</O>,
		"da": () => <O>Users</O>,
	},

	"602d627272d5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Admins</O>,
		"da": () => <O>Admins</O>,
	},

	"fddda934bc7b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nyheder</O>,
		"da": () => <O>Nyheder</O>,
	},

	"e8d94a2e3f66": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Inspiration</O>,
		"da": () => <O>Inspiration</O>,
	},

	"261eeb458499": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg</O>,
		"da": () => <O>Søg</O>,
	},

	"775b473b97a8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle nyheder</O>,
		"da": () => <O>Alle nyheder</O>,
	},

	"0eea5d96dc7a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødested synligt på hjemmesiden</O>,
		"da": () => <O>Mødested synligt på hjemmesiden</O>,
	},

	"df2eff1ff03d": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Add minimum 5 images, description, capacities, longitude and latitude before venue can be made visible</O>,
		"da": () => <O>Add minimum 5 images, description, capacities, longitude and latitude before venue can be made visible</O>,
	},

	"486d5bf99dc7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Supplier synligt på hjemmesiden</O>,
		"da": () => <O>Supplier synligt på hjemmesiden</O>,
	},

	"3377a60bfc59": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ved at vælge (klikke på et foto) et af de uploadede fotos, indstiller du det som Udvalgt billede (markeret med ikon med stjerne). Træk og slip billederne for at ændre rækkefølgen.</O>,
		"da": () => <O>Ved at vælge (klikke på et foto) et af de uploadede fotos, indstiller du det som Udvalgt billede (markeret med ikon med stjerne). Træk og slip billederne for at ændre rækkefølgen.</O>,
	},

	"0d0f416b6914": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => {
			return (
				<O>Ved at vælge (klikke på et foto) et af de uploadede fotos, indstiller du det som Udvalgt billede (markeret med ikon med stjerne).
					Træk og slip billederne for at ændre rækkefølgen.</O>
			);
		},
		"da": () => {
			return (
				<O>Ved at vælge (klikke på et foto) et af de uploadede fotos, indstiller du det som Udvalgt billede (markeret med ikon med stjerne).
					Træk og slip billederne for at ændre rækkefølgen.</O>
			);
		},
	},

	"2ee01c07adf5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Billeder skal minimum være 1200px i bredden</O>,
		"da": () => <O>Billeder skal minimum være 1200px i bredden</O>,
	},

	"90bb349b7a76": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Minimum 5 images before venue can be made visible</O>,
		"da": () => <O>Minimum 5 images before venue can be made visible</O>,
	},

	"7852b9b29737": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Delete logo</O>,
		"da": () => <O>Delete logo</O>,
	},

	"8e61e5a54dae": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Upload</O>,
		"da": () => <O>Upload</O>,
	},

	"4e924f1e29de": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>This document type is not supported. Please remove it and upload another.</O>,
		"da": () => <O>This document type is not supported. Please remove it and upload another.</O>,
	},

	"e8dbd8ccee11": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Upload billeder</O>,
		"da": () => <O>Upload billeder</O>,
	},

	"0f1def892e55": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Upload logo</O>,
		"da": () => <O>Upload logo</O>,
	},

	"283c06ec2aff": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Release to drop the files here</O>,
		"da": () => <O>Release to drop the files here</O>,
	},

	"8ca275e014fb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete image</O>,
		"da": () => <O>Delete image</O>,
	},

	"aed2402e2a4f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>You do not have access to his page. Make sure you are logged in and have the correct admin role</O>,
		"da": () => <O>You do not have access to his page. Make sure you are logged in and have the correct admin role</O>,
	},

	"067af6bb042a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Skjult</O>,
		"da": () => <O>Skjult</O>,
	},

	"5248e9c538b8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Last edited:</O>,
		"da": () => <O>Last edited:</O>,
	},

	"0a0e19b3c349": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Forhåndsvis</O>,
		"da": () => <O>Forhåndsvis</O>,
	},

	"33ae7a95a92b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Question</O>,
		"da": () => <O>Question</O>,
	},

	"55c890a23631": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Answer</O>,
		"da": () => <O>Answer</O>,
	},

	"221241a32a2d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger</O>,
		"da": () => <O>Rediger</O>,
	},

	"ff72f0d02369": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Slet</O>,
		"da": () => <O>Slet</O>,
	},

	"5843ee0939da": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilføje</O>,
		"da": () => <O>Tilføje</O>,
	},

	"ef6a05941743": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Definition på pris dagmøde:</O>,
		"da": () => <O>Definition på pris dagmøde:</O>,
	},

	"ac92f2f4b269": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dagmøde er pr. person og inkl. moms (Kl. 09:00 - 17:00)</O>,
		"da": () => <O>Dagmøde er pr. person og inkl. moms (Kl. 09:00 - 17:00)</O>,
	},

	"4d1f00191e0f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Formiddagskaffe med brød</O>,
		"da": () => <O>Formiddagskaffe med brød</O>,
	},

	"572d9729042e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Frokost med 1 stk. drikkevare pr. person</O>,
		"da": () => <O>Frokost med 1 stk. drikkevare pr. person</O>,
	},

	"ca99c30b98ba": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Eftermiddagskaffe med kage</O>,
		"da": () => <O>Eftermiddagskaffe med kage</O>,
	},

	"e6d1a549d339": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Plenumlokale med standard AV-udstyr (+ evt. grupperum efter aftale)</O>,
		"da": () => <O>Plenumlokale med standard AV-udstyr (+ evt. grupperum efter aftale)</O>,
	},

	"61b539ad1951": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>2-retters middag med 1 stk. drikkevare pr. person</O>,
		"da": () => <O>2-retters middag med 1 stk. drikkevare pr. person</O>,
	},

	"b731f87d7418": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Overnatning (i enkelt eller dobbelt værelse)</O>,
		"da": () => <O>Overnatning (i enkelt eller dobbelt værelse)</O>,
	},

	"7d2b1a1871b2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Morgenmad</O>,
		"da": () => <O>Morgenmad</O>,
	},

	"9d4e29d0e442": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Plenumlokale med standard AV-udstyr</O>,
		"da": () => <O>Plenumlokale med standard AV-udstyr</O>,
	},

	"536b7db407b4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>1 grupperum pr. 10 personer</O>,
		"da": () => <O>1 grupperum pr. 10 personer</O>,
	},

	"a0eff51c79cc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Definition på pris Kursusdøgn:</O>,
		"da": () => <O>Definition på pris Kursusdøgn:</O>,
	},

	"930187a2823d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Møde inkl. overnatning er pr. person og inkl. moms (Kl. 09:00 - 09:00)</O>,
		"da": () => <O>Møde inkl. overnatning er pr. person og inkl. moms (Kl. 09:00 - 09:00)</O>,
	},

	"33c59be0d001": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Synligt</O>,
		"da": () => <O>Synligt</O>,
	},

	"c4bd6f44c283": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Galleri</O>,
		"da": () => <O>Galleri</O>,
	},

	"5f2025c0f8c5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Logo</O>,
		"da": () => <O>Logo</O>,
	},

	"95ced136d901": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødested</O>,
		"da": () => <O>Mødested</O>,
	},

	"88171bfa01bf": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Firma / Organisation</O>,
		"da": () => <O>Firma / Organisation</O>,
	},

	"3ab9b9857d61": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Titel</O>,
		"da": () => <O>Titel</O>,
	},

	"7a807c5e6779": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Supplier start pris</O>,
		"da": () => <O>Supplier start pris</O>,
	},

	"9708dc85c24a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Permanent link</O>,
		"da": () => <O>Permanent link</O>,
	},

	"d1b04a0e82de": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Link til magasin</O>,
		"da": () => <O>Link til magasin</O>,
	},

	"cffeb9123493": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dato for magasin</O>,
		"da": () => <O>Dato for magasin</O>,
	},

	"425c1d30be68": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Fornavn</O>,
		"da": () => <O>Fornavn</O>,
	},

	"2828b0f73e2a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indsæt venligst navn</O>,
		"da": () => <O>Indsæt venligst navn</O>,
	},

	"770fa23ad39a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Address</O>,
		"da": () => <O>Address</O>,
	},

	"ae2d1791cc02": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indsæt venligst addressen</O>,
		"da": () => <O>Indsæt venligst addressen</O>,
	},

	"6a1f821c8dd2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dagsmøde pris</O>,
		"da": () => <O>Dagsmøde pris</O>,
	},

	"caa5e3946fe2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kursusdøgn pris</O>,
		"da": () => <O>Kursusdøgn pris</O>,
	},

	"e0edfb476a3d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kategori</O>,
		"da": () => <O>Kategori</O>,
	},

	"8dabb9831780": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vælg én eller flere kategorier</O>,
		"da": () => <O>Vælg én eller flere kategorier</O>,
	},

	"9233741a9575": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Slet mødested</O>,
		"da": () => <O>Slet mødested</O>,
	},

	"9134e363f91c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete supplier</O>,
		"da": () => <O>Delete supplier</O>,
	},

	"82008f248275": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Type 'delete supplier' to delete the venue</O>,
		"da": () => <O>Type 'delete supplier' to delete the venue</O>,
	},

	"acdc6368a57c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete venue' to delete the venue</O>,
		"da": () => <O>Type 'delete venue' to delete the venue</O>,
	},

	"47ed9a165b4d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partners</O>,
		"da": () => <O>Partners</O>,
	},

	"9d207a6307b6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tildel supplier til Partner</O>,
		"da": () => <O>Tildel supplier til Partner</O>,
	},

	"b68ac553b766": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tildel mødested til Partner</O>,
		"da": () => <O>Tildel mødested til Partner</O>,
	},

	"02720edc3452": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete venue</O>,
		"da": () => <O>Delete venue</O>,
	},

	"c6a7594ad63c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Type 'delete supplier' to delete the supplier</O>,
		"da": () => <O>Type 'delete supplier' to delete the supplier</O>,
	},

	"e5165948898c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete event' to delete the event</O>,
		"da": () => <O>Type 'delete event' to delete the event</O>,
	},

	"56b229b346b9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Aktiviteter</O>,
		"da": () => <O>Aktiviteter</O>,
	},

	"b3eb2570e359": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ryd</O>,
		"da": () => <O>Ryd</O>,
	},

	"788d5b7f2b23": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partnere</O>,
		"da": () => <O>Partnere</O>,
	},

	"a8a14779c798": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kategorier</O>,
		"da": () => <O>Kategorier</O>,
	},

	"be05feb58ae3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Skift adgangskode</O>,
		"da": () => <O>Skift adgangskode</O>,
	},

	"e2e984ee4200": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Reset denne brugers adgangskode</O>,
		"da": () => <O>Reset denne brugers adgangskode</O>,
	},

	"3be375fb8a2c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Din adgangskode skal indeholde mindst 8 cifre, 1 stort og 1 specialtegn</O>,
		"da": () => <O>Din adgangskode skal indeholde mindst 8 cifre, 1 stort og 1 specialtegn</O>,
	},

	"a8866d5606aa": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ny agangskode</O>,
		"da": () => <O>Ny agangskode</O>,
	},

	"cd3746fbc093": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bekræft ny adgangskode</O>,
		"da": () => <O>Bekræft ny adgangskode</O>,
	},

	"ff9e5b03d81d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partner information</O>,
		"da": () => <O>Partner information</O>,
	},

	"cbbc12f573a8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Screen name</O>,
		"da": () => <O>Screen name</O>,
	},

	"122b3af6e8d9": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Video</O>,
		"da": () => <O>Video</O>,
	},

	"ba547fa7b8c6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Navn</O>,
		"da": () => <O>Navn</O>,
	},

	"a43b62c0cf73": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>URL</O>,
		"da": () => <O>URL</O>,
	},

	"e98fb41d021a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Valg banner type før du kan tilføje billede</O>,
		"da": () => <O>Valg banner type før du kan tilføje billede</O>,
	},

	"f27530780772": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dato for arrangement</O>,
		"da": () => <O>Dato for arrangement</O>,
	},

	"f9b7bc482312": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indhold</O>,
		"da": () => <O>Indhold</O>,
	},

	"8171fc09da6a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kort beskrivelse</O>,
		"da": () => <O>Kort beskrivelse</O>,
	},

	"1e0456a7f816": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Billede</O>,
		"da": () => <O>Billede</O>,
	},

	"24913f8418a0": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Banner Billboard</O>,
		"da": () => <O>Banner Billboard</O>,
	},

	"41de55be10f2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Efternavn</O>,
		"da": () => <O>Efternavn</O>,
	},

	"dc4ab9095fcd": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Please insert valid email</O>,
		"da": () => <O>Please insert valid email</O>,
	},

	"5c6c5f49c690": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Repeat Password</O>,
		"da": () => <O>Repeat Password</O>,
	},

	"1bd65768d33c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Passwords must match</O>,
		"da": () => <O>Passwords must match</O>,
	},

	"1d84086c4a0c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Password has been reset. Please login.</O>,
		"da": () => <O>Password has been reset. Please login.</O>,
	},

	"674b4b2c787f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Please provide a valid email</O>,
		"da": () => <O>Indtast email</O>,
	},

	"e17d5a49b6ea": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Adgangskode</O>,
		"da": () => <O>Adgangskode</O>,
	},

	"e6b7cfe9f6a1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Please provide a sufficient password</O>,
		"da": () => <O>Indtast adgangskode</O>,
	},

	"fe1571301374": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Login fail</O>,
		"da": () => <O>Fejl i loginoplysninger. Tjek om du har skrevet email eller adgangskode korrekt.</O>,
	},

	"c4a6db89fe5c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Glemt adgangskode</O>,
		"da": () => <O>Glemt adgangskode</O>,
	},

	"5d117951c405": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nyt kodeord</O>,
		"da": () => <O>Nyt kodeord</O>,
	},

	"1dc91c10d279": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>New Password</O>,
		"da": () => <O>New Password</O>,
	},

	"775fc895ca27": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Adgangskoden skal være på mindst 8 tegn og indeholde 1 stor, 1 lille og en speciel tegn</O>,
		"da": () => <O>Adgangskoden skal være på mindst 8 tegn og indeholde 1 stor, 1 lille og en speciel tegn</O>,
	},

	"26e5eef678ec": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nulstilling af adgangskode mislykkedes</O>,
		"da": () => <O>Nulstilling af adgangskode mislykkedes</O>,
	},

	"9f7555e3c26c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gentag adgangskode</O>,
		"da": () => <O>Gentag adgangskode</O>,
	},

	"627a56a8c440": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kodeordene skal være ens</O>,
		"da": () => <O>Kodeordene skal være ens</O>,
	},

	"66ba11f17bbc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nulstil kodeord. Omdirigerer til login.</O>,
		"da": () => <O>Nulstil kodeord. Omdirigerer til login.</O>,
	},

	"287889d49a5a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nulstille kodeord</O>,
		"da": () => <O>Nulstille kodeord</O>,
	},

	"5d27f844f291": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>The password must be at least 8 caracters, and contain 1 uppase, 1 lower, and one special caracter</O>,
		"da": () => <O>The password must be at least 8 caracters, and contain 1 uppase, 1 lower, and one special caracter</O>,
	},

	"bd8c86118666": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Password reset failed</O>,
		"da": () => <O>Password reset failed</O>,
	},

	"831898d8cd59": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Password reset. Redirecting to login</O>,
		"da": () => <O>Password reset. Redirecting to login</O>,
	},

	"9ba13c6e7789": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Processing</O>,
		"da": () => <O>Processing</O>,
	},

	"7983850977ed": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Forhåndsvisning af kort</O>,
		"da": () => <O>Forhåndsvisning af kort</O>,
	},

	"6933fd3b8c87": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tak for din forespørgsel</O>,
		"da": () => <O>Tak for din forespørgsel</O>,
	},

	"2e2fc127ae07": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Du vil få svar hurtigst muligt</O>,
		"da": () => <O>Du vil få svar hurtigst muligt</O>,
	},

	"47e60d71d07e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Title</O>,
		"da": () => <O>Title</O>,
	},

	"13fe36a8baa8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Create</O>,
		"da": () => <O>Create</O>,
	},

	"fb252552324e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Optimeet er det tidligere Konference.dk</O>,
		"da": () => <O>Optimeet er det tidligere Konference.dk</O>,
	},

	"0d6b89cbcf6b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mine mødesteder</O>,
		"da": () => <O>Mine mødesteder</O>,
	},

	"1c3b2f1b91a4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Aktive mødesteder</O>,
		"da": () => <O>Aktive mødesteder</O>,
	},

	"e5905d98d3c3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No venue found</O>,
		"da": () => <O>No venue found</O>,
	},

	"742d583a30c9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger mødested</O>,
		"da": () => <O>Rediger mødested</O>,
	},

	"68e692f8b801": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Preview cards</O>,
		"da": () => <O>Preview cards</O>,
	},

	"f410b28ef5c8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Preview venue</O>,
		"da": () => <O>Preview venue</O>,
	},

	"d27a5a1e2d3c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No user found</O>,
		"da": () => <O>No user found</O>,
	},

	"c7418654ec01": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partner profil</O>,
		"da": () => <O>Partner profil</O>,
	},

	"7134584a7ea9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger kategori</O>,
		"da": () => <O>Rediger kategori</O>,
	},

	"23df96e2558c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger magasin</O>,
		"da": () => <O>Rediger magasin</O>,
	},

	"53fdc20c431d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger arrangement</O>,
		"da": () => <O>Rediger arrangement</O>,
	},

	"131ba734c234": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger supplier</O>,
		"da": () => <O>Rediger supplier</O>,
	},

	"25ea77270bce": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger supplier kategori</O>,
		"da": () => <O>Rediger supplier kategori</O>,
	},

	"3ac7a6e70841": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger landing category</O>,
		"da": () => <O>Rediger landing category</O>,
	},

	"b7eeb74fbbeb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger landingkategori</O>,
		"da": () => <O>Rediger landingkategori</O>,
	},

	"d4a728fe220d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Rediger landing region</O>,
		"da": () => <O>Rediger landing region</O>,
	},

	"4b73814fafa8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Du redigerer</O>,
		"da": () => <O>Du redigerer</O>,
	},

	"577b8a03ada2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Create user</O>,
		"da": () => <O>Create user</O>,
	},

	"0edfe61fb466": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Fill all values to create a user</O>,
		"da": () => <O>Fill all values to create a user</O>,
	},

	"25f85ba393e4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Opdater</O>,
		"da": () => <O>Opdater</O>,
	},

	"1187cdfc6930": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Landing synligt:</O>,
		"da": () => <O>Landing synligt:</O>,
	},

	"5dd4f3913eb8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tryk på Opdater for at gemme ændringerne</O>,
		"da": () => <O>Tryk på Opdater for at gemme ændringerne</O>,
	},

	"4b02d5c162e7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Velkommen</O>,
		"da": () => <O>Velkommen</O>,
	},

	"f6ed49f7d48c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bliv partner</O>,
		"da": () => <O>Bliv partner</O>,
	},

	"f3f2995b2949": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No search results</O>,
		"da": () => <O>No search results</O>,
	},

	"2d2b52653452": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tjek din email</O>,
		"da": () => <O>Tjek din email</O>,
	},

	"15fe1a7979fe": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>En gendannelses-email er lige blevet sendt til din emailadresse</O>,
		"da": () => <O>En gendannelses-email er lige blevet sendt til din emailadresse</O>,
	},

	"81cb1ac53ed9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Fik du ikke mailen?</O>,
		"da": () => <O>Fik du ikke mailen?</O>,
	},

	"5bb624ca60b4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Send igen</O>,
		"da": () => <O>Send igen</O>,
	},

	"e8d5cc2213f2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tak for din interesse i at blive partner. Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</O>,
		"da": () => <O>Tak for din interesse i at blive partner. Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</O>,
	},

	"58418255f6d7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Copyright © 2023 optimeet.dk. All rights reserved</O>,
		"da": () => <O>Copyright © 2023 optimeet.dk. All rights reserved</O>,
	},

	"4b065226b682": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Få hjælp til din booking</O>,
		"da": () => <O>Få hjælp til din booking</O>,
	},

	"520068d7043f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bliv Partner</O>,
		"da": () => <O>Bliv Partner</O>,
	},

	"eb3d03a2b85f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kontakt os på</O>,
		"da": () => <O>Kontakt os på</O>,
	},

	"642ab45081c9": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Check your email</O>,
		"da": () => <O>Check your email</O>,
	},

	"ae489ccd80ac": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>A recovery email was just send to your email address</O>,
		"da": () => <O>A recovery email was just send to your email address</O>,
	},

	"43956326e53a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Didn't get the email?</O>,
		"da": () => <O>Didn't get the email?</O>,
	},

	"9a8c8ebada6b": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Send again</O>,
		"da": () => <O>Send again</O>,
	},

	"d8f48582384e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gensend</O>,
		"da": () => <O>Gensend</O>,
	},

	"ba7fcc07b713": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Liste</O>,
		"da": () => <O>Liste</O>,
	},

	"27991c497406": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kort</O>,
		"da": () => <O>Kort</O>,
	},

	"0088c3442388": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg på leverandører</O>,
		"da": () => <O>Søg på leverandører</O>,
	},

	"80afbaa59cac": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg på mødested</O>,
		"da": () => <O>Søg på mødested</O>,
	},

	"7bc5a6a7bafc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hele landet</O>,
		"da": () => <O>Hele landet</O>,
	},

	"ab3c004e81aa": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Leverandørers type</O>,
		"da": () => <O>Leverandørers type</O>,
	},

	"718d9827c5b8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle typer</O>,
		"da": () => <O>Alle typer</O>,
	},

	"414a6de3b027": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Prisniveau</O>,
		"da": () => <O>Prisniveau</O>,
	},

	"f44632635ef7": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Antal gæster</O>,
		"da": () => <O>Antal gæster</O>,
	},

	"a227c515f06f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Lokation</O>,
		"da": () => <O>Lokation</O>,
	},

	"2372cc3f0e9e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle regioner</O>,
		"da": () => <O>Alle regioner</O>,
	},

	"4174306d2ebc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødestedstype</O>,
		"da": () => <O>Mødestedstype</O>,
	},

	"5e8b9c645767": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle mødestedstyper</O>,
		"da": () => <O>Alle mødestedstyper</O>,
	},

	"2c93659ca5f0": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Adresse</O>,
		"da": () => <O>Adresse</O>,
	},

	"c8f9b8aeaabe": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Region</O>,
		"da": () => <O>Region</O>,
	},

	"62a492bcffb7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>City</O>,
		"da": () => <O>City</O>,
	},

	"e963ca8362fa": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Zip</O>,
		"da": () => <O>Zip</O>,
	},

	"ea178f055f14": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Google map adresse</O>,
		"da": () => <O>Google map adresse</O>,
	},

	"fea24a4b6a11": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Længdegrad</O>,
		"da": () => <O>Længdegrad</O>,
	},

	"c2f487d315e6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Breddegrad</O>,
		"da": () => <O>Breddegrad</O>,
	},

	"ff9f92d89fd4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødesteds type</O>,
		"da": () => <O>Mødesteds type</O>,
	},

	"234a2416a3af": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tak for din tilmelding!</O>,
		"da": () => <O>Tak for din tilmelding!</O>,
	},

	"70e540835449": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Luk vindue</O>,
		"da": () => <O>Luk vindue</O>,
	},

	"b4e4f31a0dbd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Send forespørgsel</O>,
		"da": () => <O>Send forespørgsel</O>,
	},

	"c80deb040383": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Uforpligtende forespørgsel på Mødested</O>,
		"da": () => <O>Uforpligtende forespørgsel på Mødested</O>,
	},

	"eb85df895a46": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vi er altid klar til at rådgive og hjælpe dig, enten skriftligt eller via telefonen.  Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</O>,
		"da": () => <O>Vi er altid klar til at rådgive og hjælpe dig, enten skriftligt eller via telefonen.  Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</O>,
	},

	"f3557139b9c0": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>DIT NAVN *</O>,
		"da": () => <O>DIT NAVN *</O>,
	},

	"e8593a560786": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Please insert name</O>,
		"da": () => <O>Please insert name</O>,
	},

	"f0c4f57e74f3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>By</O>,
		"da": () => <O>By</O>,
	},

	"74be11b381c1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Post. nr.</O>,
		"da": () => <O>Post. nr.</O>,
	},

	"20d746dd3957": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indsæt venligst by og postnummer</O>,
		"da": () => <O>Indsæt venligst by og postnummer</O>,
	},

	"96b8b4e89a19": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Please insert town and postnumber</O>,
		"da": () => <O>Please insert town and postnumber</O>,
	},

	"e045c4ba70e3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>SPØRGSMÅL ELLER KOMMENTAR</O>,
		"da": () => <O>SPØRGSMÅL ELLER KOMMENTAR</O>,
	},

	"4af6816378e9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>FIRMA/ORGANISATION</O>,
		"da": () => <O>FIRMA/ORGANISATION</O>,
	},

	"a4106751eece": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>EMAIL *</O>,
		"da": () => <O>EMAIL *</O>,
	},

	"296fe4bc4f57": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indtast venligst en gyldig email</O>,
		"da": () => <O>Indtast venligst en gyldig email</O>,
	},

	"6811b992cb52": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Accepter venligst</O>,
		"da": () => <O>Accepter venligst</O>,
	},

	"b9c765c2ffae": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Please insert email</O>,
		"da": () => <O>Please insert email</O>,
	},

	"d7be9fbdeecb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bestil magasin</O>,
		"da": () => <O>Bestil magasin</O>,
	},

	"128764ec6e61": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Magasinbestilling gennemført!</O>,
		"da": () => <O>Magasinbestilling gennemført!</O>,
	},

	"831b078cc91b": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Magazine order successful!</O>,
		"da": () => <O>Magazine order successful!</O>,
	},

	"487b1f5222e9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bestil Optimeet Magasin</O>,
		"da": () => <O>Bestil Optimeet Magasin</O>,
	},

	"1760c948d7d8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>TELEFON NR. *</O>,
		"da": () => <O>TELEFON NR. *</O>,
	},

	"498b54ab4914": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indsæt venligst telefonnummer</O>,
		"da": () => <O>Indsæt venligst telefonnummer</O>,
	},

	"0de67e6491a4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Please insert phone number</O>,
		"da": () => <O>Please insert phone number</O>,
	},

	"e522b93d5845": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vi forsøger at vende tilbage, så hurtigt som muligt. De fleste modtager svar i løbet af samme dag</O>,
		"da": () => <O>Vi forsøger at vende tilbage, så hurtigt som muligt. De fleste modtager svar i løbet af samme dag</O>,
	},

	"7314140ed1d7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Book mødested</O>,
		"da": () => <O>Book mødested</O>,
	},

	"d4fe2fe45e47": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dagsmøde</O>,
		"da": () => <O>Dagsmøde</O>,
	},

	"92edb954e76c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kursusdøgn</O>,
		"da": () => <O>Kursusdøgn</O>,
	},

	"9b512b461a8b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find vej</O>,
		"da": () => <O>Find vej</O>,
	},

	"a469b7a6943a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Del</O>,
		"da": () => <O>Del</O>,
	},

	"4226d8158685": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>reviews</O>,
		"da": () => <O>reviews</O>,
	},

	"35af68f09a89": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Pris niveau</O>,
		"da": () => <O>Pris niveau</O>,
	},

	"c6d81377d35e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vis på kort</O>,
		"da": () => <O>Vis på kort</O>,
	},

	"60d8115d733f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": (props) => <O>Vis alle {props.data.imagesUrls.length} billeder</O>,
		"da": (props) => <O>Vis alle {props.data.imagesUrls.length} billeder</O>,
	},

	"c0f9ee2caaa4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": (props) => <O>Vis video og alle {props.data.imagesUrls.length} billeder</O>,
		"da": (props) => <O>Vis video og alle {props.data.imagesUrls.length} billeder</O>,
	},

	"2ccde27d974d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tekst</O>,
		"da": () => <O>Tekst</O>,
	},

	"8ac98e4aae80": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Slet Magasin</O>,
		"da": () => <O>Slet Magasin</O>,
	},

	"260247ee09bc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete Magazine</O>,
		"da": () => <O>Delete Magazine</O>,
	},

	"89eaaf50d118": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete magazine' to delete the magazine</O>,
		"da": () => <O>Type 'delete magazine' to delete the magazine</O>,
	},

	"5286af796ab6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Slet Arrangement</O>,
		"da": () => <O>Slet Arrangement</O>,
	},

	"ae8c32e5a9c8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Delete Banner</O>,
		"da": () => <O>Delete Banner</O>,
	},

	"b3e7f83d6bac": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Type 'delete banner' to delete the banner</O>,
		"da": () => <O>Type 'delete banner' to delete the banner</O>,
	},

	"3ef309fcdb9d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete News</O>,
		"da": () => <O>Delete News</O>,
	},

	"fb6ec7b443c2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Type 'delete news' to delete the news</O>,
		"da": () => <O>Type 'delete news' to delete the news</O>,
	},

	"4a10a8980b8c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete Event</O>,
		"da": () => <O>Delete Event</O>,
	},

	"570990016d95": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Beskrivelse</O>,
		"da": () => <O>Beskrivelse</O>,
	},

	"550fea365dd2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Delete landing kategori</O>,
		"da": () => <O>Delete landing kategori</O>,
	},

	"81f19d71de5c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Månedens konferencested</O>,
		"da": () => <O>Månedens konferencested</O>,
	},

	"0147c459a73e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Venue kategori titel</O>,
		"da": () => <O>Venue kategori titel</O>,
	},

	"e29547e67cac": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Telefon</O>,
		"da": () => <O>Telefon</O>,
	},

	"074c9954baca": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kapacitet</O>,
		"da": () => <O>Kapacitet</O>,
	},

	"3355ae9d6d39": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max Kapacitet (bio)</O>,
		"da": () => <O>Max Kapacitet (bio)</O>,
	},

	"c0041cb37c31": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max. pers. i auditorium</O>,
		"da": () => <O>Max. pers. i auditorium</O>,
	},

	"aebec177eba5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max. pers. ved skoleborde</O>,
		"da": () => <O>Max. pers. ved skoleborde</O>,
	},

	"8a5f6406c393": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max. pers. i U-bord</O>,
		"da": () => <O>Max. pers. i U-bord</O>,
	},

	"cf4106eccf81": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max. pers. i ø-opstilling á 6-8 personer</O>,
		"da": () => <O>Max. pers. i ø-opstilling á 6-8 personer</O>,
	},

	"eb5423bf07f9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max. pers. ved stående reception</O>,
		"da": () => <O>Max. pers. ved stående reception</O>,
	},

	"28f4f9c26ec8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Faciliteter</O>,
		"da": () => <O>Faciliteter</O>,
	},

	"39464575405a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg på faciliteter</O>,
		"da": () => <O>Søg på faciliteter</O>,
	},

	"4b48dd62324c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vælg én eller flere faciliteter</O>,
		"da": () => <O>Vælg én eller flere faciliteter</O>,
	},

	"55d45bce712f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Miljømærker</O>,
		"da": () => <O>Miljømærker</O>,
	},

	"fdb2557328bd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilknyt Miljømærker</O>,
		"da": () => <O>Tilknyt Miljømærker</O>,
	},

	"1a895e71a5bc": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Regioner</O>,
		"da": () => <O>Regioner</O>,
	},

	"c9fadefb9c75": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Suppliers</O>,
		"da": () => <O>Suppliers</O>,
	},

	"3e5d910acf84": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Supplier kategorier</O>,
		"da": () => <O>Supplier kategorier</O>,
	},

	"5a395007bdb1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Landingssider</O>,
		"da": () => <O>Landingssider</O>,
	},

	"6876b850c172": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Supplier Kategorier</O>,
		"da": () => <O>Supplier Kategorier</O>,
	},

	"2cfba2560660": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indlæg</O>,
		"da": () => <O>Indlæg</O>,
	},

	"53f8d6c62417": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Arrangementer</O>,
		"da": () => <O>Arrangementer</O>,
	},

	"d0d16ab00038": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle arrangementer</O>,
		"da": () => <O>Alle arrangementer</O>,
	},

	"b265697f7d33": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Magasiner</O>,
		"da": () => <O>Magasiner</O>,
	},

	"772bd24ccaec": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Banners</O>,
		"da": () => <O>Banners</O>,
	},

	"8374a65450b6": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>PDF filer</O>,
		"da": () => <O>PDF filer</O>,
	},

	"37cacbbfe6d0": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Stats</O>,
		"da": () => <O>Stats</O>,
	},

	"745ec623fa43": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Banner Skyscraper</O>,
		"da": () => <O>Banner Skyscraper</O>,
	},

	"e74b05f8be7f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg på lokationer</O>,
		"da": () => <O>Søg på lokationer</O>,
	},

	"92826063aa03": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Tilknyt Regioner</O>,
		"da": () => <O>Tilknyt Regioner</O>,
	},

	"0f0bd5e90988": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Konto</O>,
		"da": () => <O>Konto</O>,
	},

	"b5b52a6030f3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Min Profil</O>,
		"da": () => <O>Min Profil</O>,
	},

	"3c111086cfe3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Log ud</O>,
		"da": () => <O>Log ud</O>,
	},

	"1736b5fa31b8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Læs mere</O>,
		"da": () => <O>Læs mere</O>,
	},

	"3883b181ac4e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Fiskeri</O>,
		"da": () => <O>Fiskeri</O>,
	},

	"911ce7a62e3f": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Jagt</O>,
		"da": () => <O>Jagt</O>,
	},

	"02e725921975": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Boblebad</O>,
		"da": () => <O>Boblebad</O>,
	},

	"3c38dff4ca0d": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Yoga</O>,
		"da": () => <O>Yoga</O>,
	},

	"007f6ab1d6d3": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Dykning</O>,
		"da": () => <O>Dykning</O>,
	},

	"151f2b49838c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Restaurant</O>,
		"da": () => <O>Restaurant</O>,
	},

	"92d2f4662ece": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Teambuildingaktiviteter</O>,
		"da": () => <O>Teambuildingaktiviteter</O>,
	},

	"9b1c8e6a4221": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Outdoorfaciliteter</O>,
		"da": () => <O>Outdoorfaciliteter</O>,
	},

	"43c680274285": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Casino</O>,
		"da": () => <O>Casino</O>,
	},

	"bc88c212328a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Meetovation</O>,
		"da": () => <O>Meetovation</O>,
	},

	"08d502a432a8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Bar</O>,
		"da": () => <O>Bar</O>,
	},

	"57649be32786": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Fællesmadlavning</O>,
		"da": () => <O>Fællesmadlavning</O>,
	},

	"5522b6e81c7a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Kokkeskole</O>,
		"da": () => <O>Kokkeskole</O>,
	},

	"4778c1bbc71e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vandgymnastik</O>,
		"da": () => <O>Vandgymnastik</O>,
	},

	"5de44b1cf630": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Ridning</O>,
		"da": () => <O>Ridning</O>,
	},

	"b03d84c60fdd": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vinsmagning</O>,
		"da": () => <O>Vinsmagning</O>,
	},

	"07f98004e485": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Rappelling</O>,
		"da": () => <O>Rappelling</O>,
	},

	"5b0ad22d011c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Gocart</O>,
		"da": () => <O>Gocart</O>,
	},

	"ac4de7fdb9e2": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Klatring</O>,
		"da": () => <O>Klatring</O>,
	},

	"68f9f2424f69": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Karaoke</O>,
		"da": () => <O>Karaoke</O>,
	},

	"b120821c533a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Aerobic</O>,
		"da": () => <O>Aerobic</O>,
	},

	"5f09fde6693e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Grillaften</O>,
		"da": () => <O>Grillaften</O>,
	},

	"972dbe98b96b": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Kanosejlads</O>,
		"da": () => <O>Kanosejlads</O>,
	},

	"871dc8c925d1": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Parkering</O>,
		"da": () => <O>Parkering</O>,
	},

	"ae5b806c1285": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vintersport</O>,
		"da": () => <O>Vintersport</O>,
	},

	"bfe51b6bf55b": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Hjælp til mødeplanlægning</O>,
		"da": () => <O>Hjælp til mødeplanlægning</O>,
	},

	"82f2e76b7b5f": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Motionsrum</O>,
		"da": () => <O>Motionsrum</O>,
	},

	"d9446ffbcacb": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Shop</O>,
		"da": () => <O>Shop</O>,
	},

	"be73e28a6b8e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Svanemærket hotel</O>,
		"da": () => <O>Svanemærket hotel</O>,
	},

	"7ace8ce71aff": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Golf</O>,
		"da": () => <O>Golf</O>,
	},

	"ae37a211c4e5": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Sauna</O>,
		"da": () => <O>Sauna</O>,
	},

	"d8bdddbfe359": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Faciliteter og aktiviteter</O>,
		"da": () => <O>Faciliteter og aktiviteter</O>,
	},

	"c89da9926ec8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Søg blandt leverandører</O>,
		"da": () => <O>Søg blandt leverandører</O>,
	},

	"35182c80d655": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vis mindre</O>,
		"da": () => <O>Vis mindre</O>,
	},

	"39d665e91f13": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Click to delete permanently</O>,
		"da": () => <O>Click to delete permanently</O>,
	},

	"6d36ac28d116": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vælg dato</O>,
		"da": () => <O>Vælg dato</O>,
	},

	"1f98231693bd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Antal deltagere?</O>,
		"da": () => <O>Antal deltagere?</O>,
	},

	"48ac2c7e11ec": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Send en forespørgsel</O>,
		"da": () => <O>Send en forespørgsel</O>,
	},

	"2c58a35212a1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Angiv antal gæster</O>,
		"da": () => <O>Angiv antal gæster</O>,
	},

	"c7ecc6e506f6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Du får lynhurtigt svar på din henvendelse. Vi sidder klar til at yde gratis råd og vejledning.</O>,
		"da": () => <O>Du får lynhurtigt svar på din henvendelse. Vi sidder klar til at yde gratis råd og vejledning.</O>,
	},

	"e754aa6fa044": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Udfyld forespørgsel</O>,
		"da": () => <O>Udfyld forespørgsel</O>,
	},

	"c011b58ec522": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Service</O>,
		"da": () => <O>Service</O>,
	},

	"ebdb59dbcf8c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Book leverandører</O>,
		"da": () => <O>Book leverandører</O>,
	},

	"f89d0fffe8c8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Gæster</O>,
		"da": () => <O>Gæster</O>,
	},

	"e3e798c8a117": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vis flere</O>,
		"da": () => <O>Vis flere</O>,
	},

	"268290fcada6": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Søg blandt mere end 170 steder</O>,
		"da": () => <O>Søg blandt mere end 170 steder</O>,
	},

	"4fbe8abcbe76": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Resultater</O>,
		"da": () => <O>Resultater</O>,
	},

	"5a8c2081ead2": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>resultater</O>,
		"da": () => <O>resultater</O>,
	},

	"41c5e39eba92": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ryd filter</O>,
		"da": () => <O>Ryd filter</O>,
	},

	"6af82e36a691": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Fremhævet leverandører</O>,
		"da": () => <O>Fremhævet leverandører</O>,
	},

	"4ae74d9b1547": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Fremhævet steder</O>,
		"da": () => <O>Fremhævet steder</O>,
	},

	"1af7ee04e0f1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>(der matcher din søgning)</O>,
		"da": () => <O>(der matcher din søgning)</O>,
	},

	"ee29f92d5a9e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg blandt mere end 190 steder</O>,
		"da": () => <O>Søg blandt mere end 295 mødesteder</O>,
	},

	"0784177f36ad": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg blandt Møde- og Eventleverandører</O>,
		"da": () => <O>Søg blandt Møde- og Eventleverandører</O>,
	},

	"ae9de826faec": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Søg blandt leverandører</O>,
		"da": () => <O>Søg blandt suppliers</O>,
	},

	"ce9eb30654b1": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Annoncer</O>,
		"da": () => <O>Annoncer</O>,
	},

	"dd18f825e66a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Alle mødesteder</O>,
		"da": () => <O>Alle mødesteder</O>,
	},

	"f18760bcb2fd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Udvalgte muligheder til dig</O>,
		"da": () => <O>Udvalgte møde- og konferencelokaler til dig</O>,
	},

	"b3a8dc2c468c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Loading venues</O>,
		"da": () => <O>Loading venues</O>,
	},

	"14423bcb635b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>No venues found</O>,
		"da": () => <O>No venues found</O>,
	},

	"02311f7eadd7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>mødesteder</O>,
		"da": () => <O>mødesteder</O>,
	},

	"8ed654bfd941": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mere end</O>,
		"da": () => <O>Mere end</O>,
	},

	"7dfe16de7679": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gå på opdagelse i vores møde- og konferencesteder</O>,
		"da": () => <O>Gå på opdagelse i vores møde- og konferencelokaler</O>,
	},

	"6fa109b8e5b4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>More locations in</O>,
		"da": () => <O>More locations in</O>,
	},

	"0ffc28c7c96d": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Vælg arrangement type</O>,
		"da": () => <O>Vælg arrangement type</O>,
	},

	"ccffead884cb": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Angiv antal</O>,
		"da": () => <O>Angiv antal</O>,
	},

	"8c4caed21583": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indhent tilbud</O>,
		"da": () => <O>Indhent tilbud</O>,
	},

	"64cf009e48f9": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hvor?</O>,
		"da": () => <O>Hvor?</O>,
	},

	"906f2d9c70c1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Skriv by eller område</O>,
		"da": () => <O>Skriv by eller område</O>,
	},

	"154282e3e896": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Skriv dato for event</O>,
		"da": () => <O>Skriv dato for event</O>,
	},

	"b6d1a9b6df69": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Antal deltagere</O>,
		"da": () => <O>Antal deltagere</O>,
	},

	"df609251bd2c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Hvor skal det afholdes?</O>,
		"da": () => <O>Hvor skal det afholdes?</O>,
	},

	"a6809bd0f31c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Hvornår?</O>,
		"da": () => <O>Hvornår?</O>,
	},

	"47fec0c21a69": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hvad?</O>,
		"da": () => <O>Hvad?</O>,
	},

	"0f266cb94a84": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hvilken type arrangement?</O>,
		"da": () => <O>Hvilken type arrangement?</O>,
	},

	"8c2d9a3430fa": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Hvem?</O>,
		"da": () => <O>Hvem?</O>,
	},

	"28431413611c": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Viser</O>,
		"da": () => <O>Viser</O>,
	},

	"77961d482a99": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>List</O>,
		"da": () => <O>List</O>,
	},

	"026cbf381c47": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Filtre</O>,
		"da": () => <O>Filtre</O>,
	},

	"91d53094ea96": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find gratis inspiration til dig, som arbejder med møder og events</O>,
		"da": () => <O>Find gratis inspiration til dig, som arbejder med møder og events</O>,
	},

	"d8b7b8b1cc4b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => {
			return (
				<O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere.
				Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>
			);
		},
		"da": () => {
			return (
				<O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere.
				Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>
			);
		},
	},

	"f4da574da5c4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Besøg hjemmeside</O>,
		"da": () => <O>Besøg hjemmeside</O>,
	},

	"670e04433ef0": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Find Gratis inspiration til dig, som arbejder med møder og events</O>,
		"da": () => <O>Find Gratis inspiration til dig, som arbejder med møder og events</O>,
	},

	"8fa3258ec498": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Få bookinghjælp</O>,
		"da": () => <O>Få bookinghjælp</O>,
	},

	"dc0a62a266d5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Oversigt</O>,
		"da": () => <O>Oversigt</O>,
	},

	"a95c63e72cf1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dashboard</O>,
		"da": () => <O>Dashboard</O>,
	},

	"d2d0e6f5eb7a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Logout</O>,
		"da": () => <O>Logout</O>,
	},

	"e1c5659f17c2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Konferencesteder</O>,
		"da": () => <O>Konferencesteder</O>,
	},

	"69f99b5f20ba": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Venue kategori</O>,
		"da": () => <O>Venue kategori</O>,
	},

	"769710c30ab8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Leverandører</O>,
		"da": () => <O>Leverandører</O>,
	},

	"fb7761ab31ac": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødesteder</O>,
		"da": () => <O>Mødesteder</O>,
	},

	"5c5469e1b860": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Venue kategorier</O>,
		"da": () => <O>Venue kategorier</O>,
	},

	"4b1f184f2d0f": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Venue Kategorier</O>,
		"da": () => <O>Venue Kategorier</O>,
	},

	"b69321dc72c2": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>User</O>,
		"da": () => <O>User</O>,
	},

	"b45e8bd3cf63": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Visninger</O>,
		"da": () => <O>Visninger</O>,
	},

	"1a4152196c80": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Få gratis hjælp og rådgivning</O>,
		"da": () => <O>Gratis rådgivning til det rette mødelokale</O>,
	},

	"c1acbce5d022": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Populære byer med møde og konferencesteder</O>,
		"da": () => <O>Populære byer med møde og konferencesteder</O>,
	},

	"eaae205fe23a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Populære lokationer til dine møder og konferencer</O>,
		"da": () => <O>Populære lokationer til dine møder og konferencer</O>,
	},

	"e5a3f39074b5": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Populære kategorier med møde og konferencesteder</O>,
		"da": () => <O>Populære kategorier med møde og konferencesteder</O>,
	},

	"bd20ba8a6dc6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find det perfekte møde- og konferencested</O>,
		"da": () => <O>Find det perfekte møde- og konferencested</O>,
	},

	"9202d2c282c8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Få ekspertenes bud på det helt rigtige sted til jeres næste møde, konference eller event</O>,
		"da": () => <O>Få ekspertenes bud på det helt rigtige sted til jeres næste møde, konference eller event</O>,
	},

	"179080fdd50c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bliv inspireret af vores forskellige typer afholdelsessteder</O>,
		"da": () => <O>Bliv inspireret af vores forskellige typer afholdelsessteder</O>,
	},

	"98ade6418a82": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Konferencecentre</O>,
		"da": () => <O>Konferencecentre</O>,
	},

	"bf5b1e320a63": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Bliv inspireret af vores forskellige typer afholdelsessteder.</O>,
		"da": () => <O>Bliv inspireret af vores forskellige typer afholdelsessteder.</O>,
	},

	"887c87baeeb5": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Spa og Wellnesssteder</O>,
		"da": () => <O>Spa og Wellnesssteder</O>,
	},

	"8c1fbd7257ad": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Møde og konferencesteder</O>,
		"da": () => <O>Møde og konferencesteder</O>,
	},

	"4e72b67578a2": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Mindre mødesteder</O>,
		"da": () => <O>Mindre mødesteder</O>,
	},

	"6e417468562a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Kongressteder</O>,
		"da": () => <O>Kongressteder</O>,
	},

	"af99d21ab19e": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Utraditionelle steder</O>,
		"da": () => <O>Utraditionelle steder</O>,
	},

	"23897500acf1": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Slotte og herregårde</O>,
		"da": () => <O>Slotte og herregårde</O>,
	},

	"186329d67650": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Hvad leder du efter?</O>,
		"da": () => <O>Former for mødelokaler og konferencecentre</O>,
	},

	"6891359a5bb0": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Find det perfekte møde- og konferencested - Bliv inspireret af vores forskellige typer afholdelsessteder.</O>,
		"da": () => <O>Find det perfekte møde- og konferencested - Bliv inspireret af vores forskellige typer afholdelsessteder.</O>,
	},

	"8797b32de462": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dit telefonnummer</O>,
		"da": () => <O>Dit telefonnummer</O>,
	},

	"a6fb0626ebe4": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Dit email</O>,
		"da": () => <O>Dit email</O>,
	},

	"f44abcfa80a7": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kontakt os</O>,
		"da": () => <O>Kontakt os</O>,
	},

	"7247e2b47ded": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Message sent</O>,
		"da": () => <O>Message sent</O>,
	},

	"db185f62788e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Partner login</O>,
		"da": () => <O>Partner login</O>,
	},

	"e405b27ef89d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>TELEFON NR.</O>,
		"da": () => <O>TELEFON NR.</O>,
	},

	"8f8fe06dda53": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>EMAIL</O>,
		"da": () => <O>EMAIL</O>,
	},

	"e0780b089d2a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kontakt os i dag og få et</O>,
		"da": () => <O>Kontakt os i dag og få et</O>,
	},

	"ca3c10f25376": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>uforpligtende tilbud.</O>,
		"da": () => <O>uforpligtende tilbud.</O>,
	},

	"cb0558515bb1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Eller ring på tlf.</O>,
		"da": () => <O>Eller ring på tlf.</O>,
	},

	"31ef5527e02f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>+45 33 97 43 43</O>,
		"da": () => <O>+45 33 97 43 43</O>,
	},

	"7de9bb738b1b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gratis bookinghjælp</O>,
		"da": () => <O>Gratis bookinghjælp</O>,
	},

	"45268910e8ea": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Optimeet ApS</O>,
		"da": () => <O>Optimeet ApS</O>,
	},

	"48fbbfb9e389": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>CVR: 4048 4876</O>,
		"da": () => <O>CVR: 4048 4876</O>,
	},

	"70a80df804d2": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>kontakt@optimeet.dk</O>,
		"da": () => <O>kontakt@optimeet.dk</O>,
	},

	"0881a014f1f9": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>info@optimeet.dk</O>,
		"da": () => <O>info@optimeet.dk</O>,
	},

	"29e0a613b5fa": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Bookinghjælp</O>,
		"da": () => <O>Bookinghjælp</O>,
	},

	"77fbabe9c3d3": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Information</O>,
		"da": () => <O>Information</O>,
	},

	"096255f86f74": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Gruppelokaler</O>,
		"da": () => <O>Gruppelokaler</O>,
	},

	"4c726da6c5af": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Deltagere</O>,
		"da": () => <O>Deltagere</O>,
	},

	"9854d43c2a22": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Værelser</O>,
		"da": () => <O>Værelser</O>,
	},

	"baf533519f15": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Spisepladser</O>,
		"da": () => <O>Spisepladser</O>,
	},

	"bec8ea2c530e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Handikapvenlig</O>,
		"da": () => <O>Handikapvenlig</O>,
	},

	"e9e56085cbcb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Nej</O>,
		"da": () => <O>Nej</O>,
	},

	"04dd8cd323ec": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Ja</O>,
		"da": () => <O>Ja</O>,
	},

	"c34dd57dff5f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Mødelokaler</O>,
		"da": () => <O>Mødelokaler</O>,
	},

	"2e8b21a2555e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kursuscenter</O>,
		"da": () => <O>Kursuscenter</O>,
	},

	"71cc82dca98e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Konferencelokaler</O>,
		"da": () => <O>Konferencelokaler</O>,
	},

	"f9d77459c469": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Konferencehotel</O>,
		"da": () => <O>Konferencehotel</O>,
	},

	"7a04ab476328": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Messecenter</O>,
		"da": () => <O>Messecenter</O>,
	},

	"084aeb91fe3e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kursuslokaler</O>,
		"da": () => <O>Kursuslokaler</O>,
	},

	"e4f41be32fc6": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Auditorier</O>,
		"da": () => <O>Auditorier</O>,
	},

	"72e819ba2e85": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Statsaftale</O>,
		"da": () => <O>Statsaftale</O>,
	},

	"2213de8a2c99": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Handicapvenlig</O>,
		"da": () => <O>Handicapvenlig</O>,
	},

	"91f04dca5f38": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max capacitet</O>,
		"da": () => <O>Max capacitet</O>,
	},

	"6ae7df47891e": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max pers. i auditorium</O>,
		"da": () => <O>Max pers. i auditorium</O>,
	},

	"ab23a1f014cd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max pers. ved skoleborde</O>,
		"da": () => <O>Max pers. ved skoleborde</O>,
	},

	"f8d6cdccd3ad": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max pers. i U-bord</O>,
		"da": () => <O>Max pers. i U-bord</O>,
	},

	"cfa049dcd620": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max pers. i ø-opstilling á 6-8 personer</O>,
		"da": () => <O>Max pers. i ø-opstilling á 6-8 personer</O>,
	},

	"7e97d9c5452d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Max pers. ved stående reception</O>,
		"da": () => <O>Max pers. ved stående reception</O>,
	},

	"ff2f5010fb06": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kapacitet beskrivelse</O>,
		"da": () => <O>Kapacitet beskrivelse</O>,
	},

	"e88e906fcd4d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Indstillinger</O>,
		"da": () => <O>Indstillinger</O>,
	},

	"f0d9016553f8": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Slet supplier</O>,
		"da": () => <O>Slet supplier</O>,
	},

	"6704927af883": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Slet partner</O>,
		"da": () => <O>Slet partner</O>,
	},

	"96b54bbe4a71": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg på partnere</O>,
		"da": () => <O>Søg på partnere</O>,
	},

	"dcfbf4a8a930": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Valg partner</O>,
		"da": () => <O>Valg partner</O>,
	},

	"79c4acd40a02": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Miljømærkninger</O>,
		"da": () => <O>Miljømærkninger</O>,
	},

	"cec80bb689f1": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Content not found</O>,
		"da": () => <O>Content not found</O>,
	},

	"a31bd9e8b5fa": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Møde- og konferencesteder</O>,
		"da": () => <O>Møde- og konferencesteder</O>,
	},

	"ad67accd95cd": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Om os</O>,
		"da": () => <O>Om os</O>,
	},

	"31508a94fa1b": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => {
			return (
				<O>
					<p>
						OPTIMEET er Skandinaviens største internationale markedsplads indenfor møde- og eventbranchen.
					</p>
					<p>
						Vi skaber viden, værdi og relationer og forbinder møde- og eventindkøbere i
						virksomheder og organisation med hoteller, venues og andre leverandører i
						møde- og eventbranchen. Vi driver en bred vifte af fysiske og online
						aktiviteter -- fra messer, inspirationsdage og netværk til bookingplatforme,
						medier og analyser.
					</p>
					<p>
						OPTIMEET er en del af Opus Group-koncernen, der årligt formidler og gennemfører tusindvis af møder,
						konferencer, events, foredrag og oplevelser på tværs af lande og brands.
					</p>
				</O>
			);
		},
		"da": () => {
			return (
				<O>
					<p>
						OPTIMEET er Skandinaviens største internationale markedsplads indenfor møde- og eventbranchen.
					</p>
					<p>
						Vi skaber viden, værdi og relationer og forbinder møde- og eventindkøbere i
						virksomheder og organisation med hoteller, venues og andre leverandører i
						møde- og eventbranchen. Vi driver en bred vifte af fysiske og online
						aktiviteter -- fra messer, inspirationsdage og netværk til bookingplatforme,
						medier og analyser.
					</p>
					<p>
						OPTIMEET er en del af Opus Group-koncernen, der årligt formidler og gennemfører tusindvis af møder,
						konferencer, events, foredrag og oplevelser på tværs af lande og brands.
					</p>
				</O>
			);
		},
	},

	"5f831e6b6730": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere. Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>,
		"da": () => <O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere. Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>,
	},

	"49e507d3a6a6": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => {
			return (
				<O>
				  Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-,
				  kursus- og kongresplanlæggere. Værktøjerne består af håndbøger,
				  websites, messer og arrangementer, hvor mødeplanlæggere gratis kan
				  finde gode råd, tips og inspiration.
				</O>
			);
		},
		"da": () => {
			return (
				<O>
				  Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-,
				  kursus- og kongresplanlæggere. Værktøjerne består af håndbøger,
				  websites, messer og arrangementer, hvor mødeplanlæggere gratis kan
				  finde gode råd, tips og inspiration.
				</O>
			);
		},
	},

	"c5d52713ca94": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere. Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>,
		"da": () => <O>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere. Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</O>,
	},

	"94c5bf52682d": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Privatlivsbetingelser</O>,
		"da": () => <O>Privatlivsbetingelser</O>,
	},

	"a58231e771c0": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Betingelser</O>,
		"da": () => <O>Betingelser</O>,
	},

	"968eea966b4f": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Cookies</O>,
		"da": () => <O>Cookies</O>,
	},

	"588480143428": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Kontakt Os</O>,
		"da": () => <O>Kontakt Os</O>,
	},

	"e8465357342d": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Konference.dk ApS</O>,
		"da": () => <O>Konference.dk ApS</O>,
	},

	"7dd170b5e43a": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>CVR: 3605 1167</O>,
		"da": () => <O>CVR: 3605 1167</O>,
	},

	"17a7d3ba051f": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>info@konference.dk</O>,
		"da": () => <O>info@konference.dk</O>,
	},

	"56dd53e9937c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Telefon: +45 33 97 43 43</O>,
		"da": () => <O>Telefon: +45 33 97 43 43</O>,
	},

	"a19d1dbaffa5": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Søg her mellem 200+ mødesteder eller kontakt os på <br /> +45 33 97 43 43 eller info@optimeet.dk.</O>,
		"da": () => <O>Søg her mellem 200+ mødesteder eller kontakt os på <br /> +45 33 97 43 43 eller info@optimeet.dk.</O>,
	},

	"0c26aa573fe8": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Find og book dit mødelokale</O>,
		"da": () => <O>Find og book dit mødelokale</O>,
	},

	"63a047dce435": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Den førende platform til booking af møder og events</O>,
		"da": () => <O>Den førende platform til booking af møder og events</O>,
	},

	"cf60ad94e14c": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find det rette sted til dine møder og konferencer</O>,
		"da": () => <O>Find det rette sted til dine møder og konferencer</O>,
	},

	"3c7191a01093": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Find og book dit</O>,
		"da": () => <O>Find og book dit</O>,
	},

	"9cbb7928d7e6": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Book dit næste møde- og konferencested nemt her</O>,
		"da": () => <O>Book dit næste møde- og konferencested nemt her</O>,
	},

	"ffc3f1ce0b65": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Søg her mellem 200+ mødesteder eller kontakt os på <br /> +45 33 97 43 43 eller booking@optimeet.dk.</O>,
		"da": () => <O>Søg her mellem 200+ mødesteder eller kontakt os på <br /> +45 33 97 43 43 eller booking@optimeet.dk.</O>,
	},

	"cd6a9de311fb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Vi er klar med gratis rådgivning.</O>,
		"da": () => <O>Spørg direkte på et sted til dit møde og konference</O>,
	},

	"9b06055616bb": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Se mødesteder</O>,
		"da": () => <O>Se mødesteder</O>,
	},

	"aa9eb4c41fce": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find mødested efter lokation</O>,
		"da": () => <O>Find mødelokaler efter lokation</O>,
	},

	"4f369f47ffff": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Find efter lokation</O>,
		"da": () => <O>Find efter lokation</O>,
	},

	"2534b20bcd8a": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>steder</O>,
		"da": () => <O>steder</O>,
	},

	"8022bb25b281": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Forespørg</O>,
		"da": () => <O>Forespørg</O>,
	},

	"83dea628aea3": {
		"_new": true, // FIXME remove this line when translation is done
		"_deleted": true, // FIXME translation has no references; delete this entire section if you no longer need it
		"en": () => <O>Om konferencestedet</O>,
		"da": () => <O>Om konferencestedet</O>,
	},

	"1823ca099249": {
		"_new": true, // FIXME remove this line when translation is done
		"en": () => <O>Se arrangement</O>,
		"da": () => <O>Se arrangement</O>,
	},
}
